.qualities-st-ct {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    margin: 72px 0;

    .box {
        border: 1px solid #717B59;
        padding: 36px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:nth-child(1) {
            border-top: none;
            border-left: none;
        }

        &:nth-child(2) {
            border-top: none;
            border-right: none;
        }

        &:nth-child(3) {
            border-bottom: none;
            border-left: none;
        }

        &:nth-child(4) {
            border-bottom: none;
            border-right: none;
        }

        p {
            color: black;
            font-size: 18px;
            font-weight: 600;
            margin-top: 16px;
        }

        img {
            height: 64px;
            width: 64px;
        }
    }
}