.product-card {
    padding: 16px;
    border: 1px solid #A6A6A6;
    display: flex;
    flex-direction: column;

    .product-title {
        font-weight: 600;
        color: black;
        font-size: 24px;
        margin: 24px 0;
    }

    .product-desc {
        color: #767676;
        margin-bottom: 16px;
    }

    .more-details-btn {
        width: max-content;
        padding: 8px;
        background: none;
        border: 1px solid #3A5C38;
        border-radius: 8px;
        color: #3A5C38;
        cursor: pointer;
    }

    .ellipsis-text-clamp-4 {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

}