header {
    padding: 8px;
    margin: 8px 32px;

    nav {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .nav-links {
            display: flex;
            gap: 16px;

            .link {
                color: black;
                text-decoration: none;
            }
        }

        .social {
            display: flex;
            gap: 16px;
        }
    }
}