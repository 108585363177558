.custom-label {
  display: block;
  border-left-style: solid;
  border-left-width: 0px;
  margin-left: 0px;
  margin-bottom: 7px;
  border-bottom-style: solid;
  border-bottom-width: 0px;
  text-align: left;
  font-size: medium;
  color: #484647;
}

.form-control {
  width: 100%;
  display: block;
  margin: 0 auto;
  background-color: #D9D9D9 ;
}

.message{
  height: 90px;
}

.form-outer-group{
  margin-bottom: 20px;
}

.btn-clr{
  background-color: #3A5C38;
}

.pepper-image{
  position: absolute;
  left: -100px;
  height: 250px;
}

.product-image{
  position: absolute;
  right: 0px;
  bottom: 250px;
  height: 300px;
}
