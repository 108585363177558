.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.reach-out-btn {
  background-color: #3D5938;
  border-radius: 8px;
  color: white;
  padding: 8px 16px;
}

.know-more-btn {
  color: black;
  border-radius: 8px;
  background-color: white;
  padding: 8px 16px;
  border: 1px solid black;
  margin-left: 16px;
}

.footer-top-border {
  border-color: #949494 !important;
  width: 80%;
  margin: auto;
  margin-top: 96px;
}

.cl-red {
  color: #FF5F5F;
}

.cl-lg_green {
  color: #3A5C38;
}

.subscribe-btn{
  margin-left: 8px;
}