footer {
    padding: 8px;
    margin: 96px;

    display: flex;
    justify-content: space-between;

    .more-details {
        display: flex;
        gap: 100px;

        .details-head {
            font-weight: 600;
        }

        a{
            text-decoration: none;
            color: inherit;
        }
    }

    .social {
        display: flex;
        gap: 16px;
    }
}