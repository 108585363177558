.home-pg-ct {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .section-1-ct {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 150px;
        margin-top: 50px;

        .section-1 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 500px;

            .tagline2 {
                font-size: 64px;
                font-weight: 800;
            }

            .btns-ct {
                margin: 20px;
            }
        }

        .cinamon-placeholder,
        .pepper-placeholder,
        .cinnamon-placeholder,
        .cardamon-placeholder {
            position: absolute;
            right: 0%;
            top: -25%;
        }

        .left-aligned {
            left: 0;
        }

        .pepper-placeholder {
            top: 75%;
            transform: translateX(-50%);
        }

        .cinnamon-placeholder {
            right: 0;
            top: 5%;
            transform: translateX(50%);
        }

        .cardamon-placeholder {
            right: 0;
            top: 60%;
            height: 200px;
            transform: translateX(50%);
        }
    }

    .qualities-ct {
        display: flex;
        width: 90%;
        justify-content: flex-start;
        position: relative;

        .floating-ct {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 320px;

            .smile {
                font-size: 36px;
            }

            .content-ct {
                position: absolute;
                top: 15%;
                left: 20%;
                text-align: center;
                transform: rotate(-10deg);
                color: #767676;
            }
        }
    }

    .products-ct {
        width: 90%;
        position: relative;
        margin: 72px;

        h2 {
            color: #3A5C38;
        }

        .pepper-placeholder {
            position: absolute;
            right: -75px;
            top: -100px;
            transform: translateY(50%);
            width: 200px;
        }

        .product-cards-ct {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            margin-top: 60px;
        }
    }

    .why-us {
        width: 90%;
        margin: 72px;

        h2 {
            color: #3A5C38;
            // margin-left: 16px;

            &::before {
                content: "";
                border-right: 4px solid #FADABF;
                margin-right: 16px;
            }
        }

        p {
            margin: 0;
            margin-left: 20px;
            color: #000000;
        }
    }

    .processing-steps {
        width: 100%;
        padding: 72px;
        position: relative;

        .content-container {
            width: 60%;
            margin: auto;
            text-align: center;

            h2 {
                color: #3A5C38;
                font-weight: bold;
            }

            p {
                color: #767676;
            }
        }

        .processing-1 {
            position: absolute;
            top: 0;
            transform: translate(-30%, -25%);
        }

        .processing-2 {
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(0, -25%);
        }

        .grid-container {
            margin-top: 100px;
            display: flex;
            gap: 16px;
            justify-content: center;

            .step-ct {
                padding: 16px;
                background-color: #3A5C38;
                color: white;
                text-align: center;
                border-radius: 24px;
                cursor: pointer;

                display: flex;
                    flex-direction: column;
                    justify-content: center;

                &:nth-child(odd) {
                    background: #FADABF;
                    color: black;
                }

                &.active{
                    display: block;
                    img{
                        display: inline;
                    }

                    .title{
                        transform: none;
                        width: auto;
                        white-space: normal;
                    }
                }

                img {
                    width: 400px;
                    height: 400px;

                    display: none;
                }

                .title {
                    font-size: 20px;
                    font-weight: 500;
                    margin: 16px 0;

                    transform: rotate(-90deg);
                    white-space: nowrap;
                    width: 100%;
                }
            }
        }
    }

    .subscribe {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 72px;

        .subscribe-content {
            margin: 36px;
            width: 500px;
            text-align: center;
        }

        h2 {
            margin-bottom: 32px;
        }

        .cinamon-placeholder {
            position: absolute;
            right: 0%;
            top: 50%;
            transform: translate(50%, -50%);
        }

        .left-aligned {
            left: 0;
            transform: translate(0%, -50%);
        }
    }
}